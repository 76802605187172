import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import { omit } from "lodash";
import React from "react";

export const WarningIcon = (props: React.ComponentProps<typeof StyledSvg> & { backgroundColor: string; $disabled: boolean }) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <rect x="7" y="5" width="2" height="8" fill={"transparent"} />
      <path
        d="M7.99998 1.59998C7.55648 1.59998 7.17655 1.87023 7.01561 2.25518L1.22707 12.2385V12.2396C1.1226 12.4078 1.06705 12.6019 1.06665 12.8C1.06665 13.0829 1.17903 13.3542 1.37907 13.5542C1.57911 13.7543 1.85042 13.8666 2.13332 13.8666C2.15835 13.8665 2.18336 13.8654 2.20832 13.8635L2.2104 13.8666H7.99998H13.7896L13.7917 13.8625C13.8166 13.8647 13.8416 13.8661 13.8667 13.8666C14.1495 13.8666 14.4209 13.7543 14.6209 13.5542C14.8209 13.3542 14.9333 13.0829 14.9333 12.8C14.9331 12.6015 14.8776 12.4071 14.7729 12.2385L14.7646 12.2239C14.7642 12.2236 14.7639 12.2232 14.7635 12.2229L8.98436 2.25518C8.82342 1.87023 8.44349 1.59998 7.99998 1.59998ZM7.35311 6.05831H8.64686L8.53957 9.51039H7.4604L7.35311 6.05831ZM8.00207 10.5656C8.44047 10.5656 8.70311 10.8015 8.70311 11.2041C8.70311 11.5993 8.44047 11.8343 8.00207 11.8343C7.56047 11.8343 7.29582 11.5993 7.29582 11.2041C7.29582 10.8015 7.55993 10.5656 8.00207 10.5656Z"
        fill={props.color ?? (props.$disabled ? $theme.colors.contentStateDisabled : $theme.colors.yellow500)}
      />
    </StyledSvg>
  );
};
