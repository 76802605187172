import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const SuccessCircleIcon = (props: React.ComponentProps<typeof StyledSvg> & { $disabled: boolean }) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 16 16" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 14.4C11.5346 14.4 14.4 11.5346 14.4 8.00001C14.4 4.46538 11.5346 1.60001 8.00001 1.60001C4.46538 1.60001 1.60001 4.46538 1.60001 8.00001C1.60001 11.5346 4.46538 14.4 8.00001 14.4ZM7.28262 10.5032L11.3104 6.28575C11.5188 6.06763 11.5188 5.71506 11.3104 5.49693C11.1021 5.27881 10.7654 5.27881 10.5571 5.49693L6.90594 9.31999L5.44293 7.78809C5.23461 7.56996 4.89789 7.56996 4.68958 7.78809C4.48126 8.00621 4.48126 8.35878 4.68958 8.57691L6.52927 10.5032C6.6289 10.6081 6.76476 10.6667 6.90594 10.6667C7.04713 10.6667 7.18246 10.6081 7.28262 10.5032Z"
        fill={props.color ?? (props.$disabled ? $theme.colors.contentStateDisabled : $theme.colors.green600)}
      />
    </StyledSvg>
  );
};
