import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const FailureCircleIcon = (props: React.ComponentProps<typeof StyledSvg> & { $disabled: boolean }) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 16 16" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00001 1.60001C4.46561 1.60001 1.60001 4.46561 1.60001 8.00001C1.60001 11.5344 4.46561 14.4 8.00001 14.4C11.5344 14.4 14.4 11.5344 14.4 8.00001C14.4 4.46561 11.5344 1.60001 8.00001 1.60001ZM10.5104 9.75627C10.7189 9.96481 10.7189 10.3019 10.5104 10.5104C10.4064 10.6144 10.2699 10.6667 10.1333 10.6667C9.99681 10.6667 9.86027 10.6144 9.75627 10.5104L8.00001 8.75414L6.24374 10.5104C6.13974 10.6144 6.00321 10.6667 5.86667 10.6667C5.73014 10.6667 5.59361 10.6144 5.48961 10.5104C5.28107 10.3019 5.28107 9.96481 5.48961 9.75627L7.24587 8.00001L5.48961 6.24374C5.28107 6.03521 5.28107 5.69814 5.48961 5.48961C5.69814 5.28107 6.03521 5.28107 6.24374 5.48961L8.00001 7.24587L9.75627 5.48961C9.96481 5.28107 10.3019 5.28107 10.5104 5.48961C10.7189 5.69814 10.7189 6.03521 10.5104 6.24374L8.75414 8.00001L10.5104 9.75627Z"
        fill={props.color ?? (props.$disabled ? $theme.colors.contentStateDisabled : $theme.colors.red500)}
      />
    </StyledSvg>
  );
};
